import * as React from 'react'
import styled from 'styled-components'
import {useState, useRef, useLayoutEffect, useCallback} from "react";

const Title = styled.div`
  width: 100%;
  font-family: Merriweather, serif;
  font-size: 1.2em;
  padding: 0.5em 1em;
  color: ${props => props.expanded ? `#ffffff` : `#d8d8d8`};
  user-select: none;
  box-sizing: border-box;
  border-radius: ${props => props.expanded ? `8px 8px 0 0` : `8px`};
  transition: background-color 0.25s, border-radius 0.25s;
  background-color: ${props => props.expanded ?
          `#ff008c99`
          : `transparent`};
  text-transform: lowercase;
  ::before{
    color: ${props => props.expanded ? `#ffffff` : `#ff008c`};
    text-align: right;
    content: ${props => props.expanded ? `"<"` : `">"`};
    margin-right: 0.5em;
    white-space: pre;
  }
  @media (min-width: 600px){
    :hover{
      background-color: ${props => props.expanded ?
              `rgb(255, 0, 140)`
              : `rgba(255, 255, 255, 0.1)`};
    }
  }
  cursor: pointer;
`
const Content = styled.div`
  overflow-y: hidden;
  padding: ${props => props.expanded ? `1em` : `0 1em`};
  :last-child{
    padding-bottom: 0;
  }
  transition: opacity 0.25s ease-out, max-height 0.25s ease-out, padding 0.25s ease-out;
  height: auto;
  max-height: ${props => props.expanded ? props.contentHeight + `px` : `0`};
  opacity: ${props => props.expanded ? `1` : `0`};
`

const Main = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #212121;
  margin: 8px 0;
`

const Accordian = (props, children) => {
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    // const contentEl = useCallback(node => {
    //     if (node !== null) {
    //         setContentHeight(node.scrollHeight);
    //     }
    // }, []);
    const contentEl = useRef(null);
    useLayoutEffect(() => {
       if(contentEl !== null){
           setContentHeight(contentEl.current.scrollHeight);
       }
    });
    const handleTitleClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };
    return(
        <Main>
            <Title onClick={handleTitleClick} expanded={expanded}>
                {props.title}
            </Title>
            <Content expanded={expanded} ref={contentEl} contentHeight={contentHeight}>
                {props.children}
            </Content>
        </Main>
    )
}

export default Accordian
