import * as React from 'react'
import styled from 'styled-components'
import {Link} from "gatsby";
import SHLHeader from "../components/SHL-header";
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Accordian from "../components/accordion";
import ResponsiveImage from "../components/responsive-image";
import Pill from "../components/pill";

const pageStyles = {
    fontFamily: "Roboto, sans-serif, serif",
    width: "auto",
    height: "auto",
    minHeight: "100vh",
    padding: "auto",
}
const contentStyles = {
    maxWidth: "768px",
    margin: "auto",
    padding: "16px",
    color: "#d8d8d8",
    backgroundColor: "#181818cc",
    marginBottom: "16px",
    borderRadius: "8px"
}
const Header2 = styled.h2`
  font-family: Merriweather, "Noto Serif TC", serif;
  font-size: 1.3em;
`
const Header6 = styled.h6`
  font-family: Merriweather, "Noto Serif TC", serif;
  font-weight: bold;
  margin: 0 0 1em 0;
  font-size: 1.3em;
  text-transform: lowercase;
  ::after{
    content: ". ";
    white-space: pre;
  }
`
const P = styled.p`
  font-family: Merriweather, "Noto Serif TC", serif;
  hyphens: auto;
  margin: 0 0 1em 0;
  text-indent: 1em;
  @media (min-width: 600px){
    text-align: justify;
  }
`
const Portrait = styled(ResponsiveImage)`
  border-radius: 8px;
  picture img {
    filter: sepia(100%) saturate(150%) hue-rotate(-75deg);
  }
  @media(min-width: 600px){
    width: 40%;
    float: right;
    margin: 16px 0 16px 16px;
  }
`
const ActionItem = styled.div`
  font-family: Merriweather, "Noto Serif TC", serif;
  font-size: 1.2em;
  div {
    height: 64px;
    
  }
  svg{
    height: 1.5em;
    padding-bottom: 4px;
    width: auto;
    vertical-align: middle;
    color: #ff008c;
  }


  a {
    color: #ff008c;
    transition: color 0.25s;
    height: 100%;
    position: relative;
    z-index: 1;
    ::after {
      content: "";
      display: block;
      position: absolute;
      background: #ff008c;
      height: 100%;
      transform: scaleY(0);
      transform-origin: bottom;
      bottom: 0;
      border-radius: 4px;
      left: -1px;
      right: -1px;
      margin: 0 auto;
      z-index: -1;
    }

    :hover ::after {
      max-height: 100%;
      animation: scaleY-easeOutElastic 0.75s;
      animation-fill-mode: forwards;
    }

    :hover {
      color: white !important;
    }
  }
`
const ActionGroup = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ff008c;
  clear: right;
`
const FullContentWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 FadeInDown;
  a {
    color: #ff008c;
    transition: color 0.25s;
    height: 100%;
    :hover {
      color: white;
    }
  }
`

const AboutPage = () => {
    return (
        <main style={pageStyles}>
            <div style={contentStyles}>
                <title>About | SH Liu | rarakasm</title>
                <SHLHeader subtitle="About"/>
                <FullContentWrapper>
                    <Portrait src={"/assets/portrait.jpg"}/>
                    <Header2>hello there</Header2>
                    <P>
                        My name is <strong>Shi-Hong Liu (劉式閎)</strong>, and my online handle is <strong>rarakasm</strong> - or just <strong>rak /ræk/</strong> if you, like me, have trouble pronouncing that one.
                    </P>
                    <P>
                        I live in Taoyuan, TW, and I work at <a target="_blank" href="https://synology.com">Synology</a> as a software engineer in New Taipei City, TW.
                        In the first year, I shipped the web frontend for <a target="_blank" href="https://backup.c2.synology.com">C2 Backup</a>.
                        After its release, I built <a target="_blank" href="https://password.c2.synology.com">the Android client for C2 Password</a> from scratch, my current main project.
                        I'm most proficient in any sort of user-facing applications (mobile, front-end, game dev).
                    </P>
                    <P>
                        I graduated from Graduate Institute of Networking and Multimedia, National Taiwan University as a Master's student.
                        My research area focused on <strong>Human-Computer Interaction (HCI)</strong>, or more specifically, haptic interactions in virtual reality.
                    </P>
                    {/*<Header2>important stuff first and foremost</Header2>*/}
                    <ActionGroup>
                        <ActionItem><EmailIcon/> <a href="mailto:shliu@rarakasm.com" rel="noreferrer">shliu@rarakasm.com</a></ActionItem>
                        <ActionItem><LinkedInIcon/> <a href="https://www.linkedin.com/in/shih-hung-liu/" target={"_blank"} rel="noreferrer">LinkedIn</a></ActionItem>
                        <ActionItem><DescriptionIcon/> <a href="/assets/files/resume.pdf" target={"_blank"} rel="noreferrer">Resume</a></ActionItem>
                        <ActionItem><DashboardIcon/> <Link to="/portfolio">Portfolio</Link></ActionItem>
                    </ActionGroup>
                    {/*<Header2>...and the unimportant stuff</Header2>*/}
                    {/*<Accordian title="Bio">*/}
                    {/*    <P>to be added</P>*/}
                    {/*</Accordian>*/}
                    {/*<Accordian title="Music">*/}
                    {/*    <P>to be added</P>*/}
                    {/*</Accordian>*/}
                    {/*<Accordian title="Reading">*/}
                    {/*    <P>to be added</P>*/}
                    {/*</Accordian>*/}
                    {/*<Accordian title="Gaming">*/}
                    {/*    <P>to be added</P>*/}
                    {/*</Accordian>*/}
                    {/*<Accordian title="Competitive Programming">*/}
                    {/*    <P>I'd consider myself a casual competitive programmer even though that sounds like an oxymoron.</P>*/}
                    {/*    <P>*/}
                    {/*        I started doing LeetCode out of career anxiety a few months before graduation,*/}
                    {/*        but ended up so addicted to the thrill of solving these questions that I began to participate in their weekly contests.*/}
                    {/*        During military service, I would bring printouts of contest problems from codeforces and atcoder every week so I could think on them when idling and marching.*/}
                    {/*        It turned out to be a very effective way to pass time*/}
                    {/*        - just read the problem once, memorize it and put it away, no need to hold it in my hand asking for troubles*/}
                    {/*        - and also to keep my mind functioning amid the numbing ways of the army.*/}
                    {/*    </P>*/}
                    {/*    <P>*/}
                    {/*        I initially refrained from doing contests on real competition sites because I feared that it's spiral downwards to a huge time sink and detrimental for landing a job.*/}
                    {/*        But after getting out of the army, I thought to myself "why not?" and began to participate in codeforces and atcoder contests to see how far I can go.*/}
                    {/*        Well, turns out I'm not particularly good at it, and I regret not picking this up during my high school years. But I still think it's lots of fun. Like sudoku but cooler.*/}
                    {/*    </P>*/}
                    {/*    <P>*/}
                    {/*        My goal now is to clear a Div.2 contest.*/}
                    {/*    </P>*/}
                    {/*    <P><a href="https://clist.by/coder/rarakasm/" target="_blank" rel="noreferrer">My clist.by profile</a></P>*/}
                    {/*</Accordian>*/}
                </FullContentWrapper>
            </div>
        </main>
    )
}

export default AboutPage
